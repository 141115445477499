import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { BooksPageComponent } from './pages/books-page/books-page.component';
import { VideosPageComponent } from './pages/videos-page/videos-page.component';
import { ArticlesPageComponent } from './pages/articles-page/articles-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { PopUpComponent } from './pop-up/pop-up.component';
import { HttpClientModule } from '@angular/common/http';
import { FatwaPageComponent } from './pages/fatwa-page/fatwa-page.component';
import { SpeechesComponent } from './pages/speeches/speeches.component';
import { PdfViewerComponent } from './pages/pdf-viewer/pdf-viewer.component'





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    BooksPageComponent,
    VideosPageComponent,
    ArticlesPageComponent,
    PopUpComponent,
    FatwaPageComponent,
    SpeechesComponent,
    PdfViewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    AngularFireModule.initializeApp(environment.firebase),
    provideFirestore(() => getFirestore()),
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
