import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { DBService } from '../service/db.service';

@Component({
  providers: [AppComponent],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(private dbService: DBService) {

  }



  popUp = false;


  ngOnInit(): void {
  }

  ngAfterContentInit() {
    this.dbService.popUpStatus.subscribe(data => {
      this.popUp = data.valueOf();
    })
  }

  popupStatus(status: boolean) {
    this.dbService.changePopUpStatus(status);
  }

}