<div class="container" style="margin-top: 155px;">
    <!-- <div class="row">
        <div style="width: 100%; height: 0.1px; background-color: #252524;"></div>
    </div> -->
    <div class="row from-top">
        <div class="col-12 titel">جميع الخطب</div>
    </div>
    <div class="row from-top">
        <div class="col-12 text-right text-titel">
            <img src="assets/image/home-icon.svg" width="18px" style="padding-bottom: 2px;" />
            الرئيسية > خطب الجمعة
        </div>
    </div>
    <div class="row from-top">
        <div class="col" style="text-align: right;">
            <input (keyup)="onKey($event)" class="input-search-titel" placeholder="إبحث عن الخطب">
            <button (click)="findBySpeeches(speechesTitle)" class="button-search">
                <img src="assets/image/search-icon.svg" alt="">
            </button>
        </div>
    </div>
</div>


<div class="container vertical-scrollable from-top-speeches" style="text-align: center;">
    <div class="row" *ngIf="speechesList.length != 0">
        <div class="col-12 background" *ngFor="let speeche of speechesList; let i = index">
            <img class="col-2 image-content" [src]="speeche.image" width="100%">
            <div class="col-2 titel-content">{{speeche.titel}}</div>
            <div class="col-6 subject-content">
                <span *ngIf="!showFull">
                    {{ getShortContent(speeche.subject) }}...
                </span>
                <span *ngIf="showFull">
                    <app-pdf-viewer [iframeUrl]="speeche.url"></app-pdf-viewer>
                </span>
            </div>
            <div class="col-2" style="float: left;cursor: pointer;text-align: left; padding-bottom: 10px;
            padding-left: 20px;" (click)="toggleContent()">
                <img *ngIf="!showFull" src="/assets/icons/down-arrow-svgrepo-com.svg" width="10%">
                <img *ngIf="showFull" src="/assets/icons/up-arrow-svgrepo-com.svg" width="10%">
            </div>
            <button *ngIf="showDeleteIcon" class="delete-but" (click)="delete(speeche.id)">حذف</button>
        </div>
    </div>
    <div class="msg" *ngIf="speechesList.length === 0">
        لا يوجد خطب
    </div>
</div>