<!-- <div class="container" style="width: 80%; height: 2.10px; background-color: #eae3cb;margin-top: 50px;"></div> -->
<footer class="footer" dir="ltr">
    <div class="footer__contactBox">
        <div class="footer__logo">
            <a href="/" class="footer__logoLink">
                <img alt="" class="footer__logoImg footer_logonas" src="/assets/image/logoWebsite.svg" />
            </a>
        </div>

        <div>
            <a href="https://goo.gl/maps/usFHPuQ1rBYg3tP77" target="_blank" class="footer__contact footer__contactBox">
                السعودية - الرياض - حي الحمراء
            </a>
        </div>

        <div>
            <a href="mailto:email@email.com" class="footer__contact footer__contact--mail">
                awadaan@gmail.com
            </a>
        </div>
    </div>

    <div class="footer__copy">
        <div class="footer__links">
        </div>
        <div class="footer__copyright" style="cursor: pointer;font-size: 12px;font-weight: bold;"
            (click)="goToLink('https://naseelsolutions.com')">
            صنع بواسطه حلول نسيل © 2023
        </div>
        <div class="footer__logoWrapper">
            <div class="footer__copyLogo">
            </div>
            <div class="footer__copyLogo">
            </div>
        </div>
    </div>
</footer>