import { Injectable, Inject } from '@angular/core';
import { collection, collectionData, deleteDoc, Firestore, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { addDoc } from '@firebase/firestore';
import { Observable, Subject } from 'rxjs';
import { ResponseBook, ResponseVideo, ResponseArticles, RequestArticles, RequestBook, RequesteVideo, Data, ResponseFatwa, RequestFatwa, ResponseSpeeches, RequestSpeeches } from '../interface/response';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/compat/database';



@Injectable({
  providedIn: 'root'
})
export class DBService {

  constructor(private firestore: Firestore, private db: AngularFirestore, @Inject(AngularFireDatabase) private firebase: AngularFireDatabase) { }

  topThreeBookTitle: string[] = [];
  topThreeVideoTitle: string[] = [];
  topThreeArticleTitle: string[] = [];
  topThreeFatwaTitle: string[] = [];
  topThreeSpeecheTitle: string[] = [];


  popUpStatus = new Subject<Boolean>();
  dateFile !: AngularFireList<any>;
  dataSet: Data = {
    id: '',
    url: ''
  };
  imageDetailList !: AngularFireList<any>;
  fileList !: any[];
  msg: string = 'error';

  showDeleteIcon = false;


  addBook(book: RequestBook, file: any) {
    let $bookRef = collection(this.firestore, "book");
    return addDoc($bookRef, book);
  }

  addVideo(video: RequesteVideo) {
    let $videoRef = collection(this.firestore, "video");
    return addDoc($videoRef, video);
  }

  addArticle(article: RequestArticles) {
    let $articleRef = collection(this.firestore, "article");
    return addDoc($articleRef, article);
  }

  addSpeeches(speeches: RequestSpeeches) {
    let $articleRef = collection(this.firestore, "speeche");
    return addDoc($articleRef, speeches);
  }

  addFatwa(article: RequestFatwa) {
    let $articleRef = collection(this.firestore, "fatwa");
    return addDoc($articleRef, article);
  }


  getVideo() {
    let $videoRef = collection(this.firestore, "video");
    return collectionData($videoRef, { idField: "id" }) as Observable<ResponseVideo[]>
  }

  getVideoType(videoType: number) {
    return this.db.collection<ResponseVideo>("video",
      ref => ref.where("typeVideo", "==", videoType)
    ).valueChanges({ idField: 'id' });
  }

  getArticle() {
    let $articleRef = collection(this.firestore, "article");
    return collectionData($articleRef, { idField: "id" }) as Observable<ResponseArticles[]>
  }

  getFatwa() {
    let $fatwaRef = collection(this.firestore, "fatwa");
    return collectionData($fatwaRef, { idField: "id" }) as Observable<ResponseFatwa[]>
  }

  getSpeeches() {
    let $speechesRef = collection(this.firestore, "speeche");
    return collectionData($speechesRef, { idField: "id" }) as Observable<ResponseSpeeches[]>
  }


  getBook() {
    let $bookRef = collection(this.firestore, "book");
    return collectionData($bookRef, { idField: "id" }) as Observable<ResponseBook[]>
  }

  getBookByType(bookType: number) {
    return this.db.collection("book",
      ref => ref.where("bookType", "==", bookType)).valueChanges() as Observable<ResponseBook[]>;
  }

  //need to enhasment
  getUsers(email: string, password: string) {
    return this.db.collection("users", ref => ref.where("email", "==", email).where("password", "==", password)).valueChanges();
  }

  addToken(token: any) {
    this.db.collection("token").add(token).then(ref => {
      localStorage.setItem('loginToken', token.token);
      localStorage.setItem('loginTokenId', ref.id);
    });
  }

  getToken(id: string) {
    return this.db.collection("token").doc(id).valueChanges();
  }

  deleteToken(id: string) {
    this.db.collection("token").doc(id).delete();
  }

  randomString(length: number) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#@$%&*';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  changePopUpStatus(status: boolean) {
    this.popUpStatus.next(status);
  }


  //

  insertFile(id: any, url: any) {
    this.dataSet = {
      id: id,
      url: url
    };
    this.imageDetailList.push(this.dataSet);
  }

  getFile() {
    this.imageDetailList = this.firebase.list('/files');
  }

  returnFile(fileId: number) {
    this.getFile()
    this.imageDetailList.snapshotChanges().subscribe(
      list => {
        this.fileList = list.map(item => { return item.payload.val(); });
        this.fileList.forEach(element => {
          console.log(element);
          if (element.name === fileId)
            return element.url;
        });
        if (this.msg === 'error')
          alert('لا يوجد ملفات');
        else {
          this.msg = 'find file';
        }
      }
    );
    return null;
  }
  setValueDeleteIcon(value: boolean) {
    console.log(value);

    this.showDeleteIcon = value;
    return;
  }

  getValueDeleteIcon() {
    console.log(this.showDeleteIcon);

    return this.showDeleteIcon;
  }

  getTopThreeSubjectBookTitels() {
    this.topThreeBookTitle = [];
    this.db.collection<ResponseBook>("book", ref => ref.limit(3)).valueChanges().subscribe(data => {
      data.forEach(book => {
        this.topThreeBookTitle.push(book.titel);
      });
    });
    return this.topThreeBookTitle;
  }

  getTopThreeSubjectVideoTitels() {
    this.topThreeVideoTitle = []
    this.db.collection<ResponseVideo>("video", ref => ref.limit(3)).valueChanges().subscribe(data => {
      data.forEach(video => {
        this.topThreeVideoTitle.push(video.titel);
      });
    });
    return this.topThreeVideoTitle;
  }

  getTopThreeSubjectSpeecheTitels() {
    this.topThreeSpeecheTitle = []
    this.db.collection<RequestSpeeches>("speeche", ref => ref.limit(3)).valueChanges().subscribe(data => {
      data.forEach(speeche => {
        this.topThreeSpeecheTitle.push(speeche.titel);
      });
    });
    return this.topThreeSpeecheTitle;
  }

  getTopThreeSubjectArticleTitels() {
    this.topThreeArticleTitle = []
    this.db.collection<ResponseArticles>("article", ref => ref.limit(3)).valueChanges().subscribe(data => {
      data.forEach(article => {
        this.topThreeArticleTitle.push(article.titel);
      });
    });
    return this.topThreeArticleTitle;
  }

  getTopThreeSubjectFatwaTitels() {
    this.topThreeFatwaTitle = []
    this.db.collection<ResponseFatwa>("fatwa", ref => ref.limit(3)).valueChanges().subscribe(data => {
      data.forEach(fatwa => {
        this.topThreeFatwaTitle.push(fatwa.titel);
      });
    });
    return this.topThreeFatwaTitle;
  }

  deleteItem(page: string, id: string) {
    this.db.collection(page).doc(id).delete().then(() => {

      console.log("Document successfully deleted!");
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

}
