<div class="container" style="margin-top: 155px;">
    <!-- <div class="row">
        <div style="width: 100%; height: 0.1px; background-color: #252524;"></div>
    </div> -->
    <div class="row from-top">
        <div class="col-12 titel">جميع الفديوهات</div>
    </div>
    <div class="row from-top">
        <div class="col-12 text-right text-titel">
            <img src="assets/image/home-icon.svg" width="18px" style="padding-bottom: 2px;" />
            الرئيسية > الفديوهات
        </div>
    </div>
    <div class="row from-top">
        <div class="col" style="text-align: right;">
            <input (keyup)="onKey($event)" class="input-search-titel" placeholder="إبحث عن الفديوهات">
            <button (click)="findByVideo(videoFind)" class="button-search">
                <img src="assets/image/search-icon.svg" alt="">
            </button>
        </div>
    </div>
</div>

<h1 class="title-subject">المقاطع القصيرة</h1>
<div class="container vertical-scrollable from-top-videos" style="text-align: center;">
    <div *ngIf="videoListShort.length != 0" class="row d-flex flex-row-reverse justify-content-between">
        <div class="col-4 video-comp" *ngFor="let video of videoListShort; let i = index">
            <div class="video">
                <iframe [src]=enableVido(video.videoLink) width="100%" height="100%" frameborder="0" allowfullscreen>
                </iframe>
            </div>
            <div class="video-title">{{video.titel}}</div>
            <button *ngIf="showDeleteIcon" class="delete-but" (click)="delete(video.id)">حذف</button>
        </div>
    </div>
    <div class="msg" *ngIf="videoListShort.length === 0">
        لا يوجد فديوهات
    </div>
</div>

<h1 class="title-subject">المقاطع الطويلة</h1>
<div class="container vertical-scrollable from-top-videos" style="text-align: center;">
    <div *ngIf="videoListLong.length != 0" class="row d-flex flex-row-reverse justify-content-between">
        <div class="col-4 video-comp" *ngFor="let video of videoListLong; let i = index">
            <div class="video">
                <iframe [src]=enableVido(video.videoLink) width="100%" height="100%">
                </iframe>
            </div>
            <div class="video-title">{{video.titel}}</div>
            <button *ngIf="showDeleteIcon" class="delete-but" (click)="delete(video.id)">حذف</button>
        </div>
    </div>
    <div class="msg" *ngIf="videoListLong.length === 0">
        لا يوجد فديوهات
    </div>
</div>