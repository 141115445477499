// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'was-project-f15a3',
    appId: '1:751527939576:web:d68d2b3c76a0b4a4225a0d',
    databaseURL: 'https://was-project-f15a3-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: 'was-project-f15a3.appspot.com',
    apiKey: 'AIzaSyDQQP-CaZFjykG4u6PURkTzdF1beGai0G0',
    authDomain: 'was-project-f15a3.firebaseapp.com',
    messagingSenderId: '751527939576',
    measurementId: 'G-SKWEM59RZX',
  },
  production: false,
};

