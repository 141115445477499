import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ResponseArticles } from 'src/app/interface/response';
import { DBService } from 'src/app/service/db.service';

@Component({
  selector: 'app-articles-page',
  templateUrl: './articles-page.component.html',
  styleUrls: ['./articles-page.component.css']
})
export class ArticlesPageComponent implements OnInit {

  constructor(private dbService: DBService,
    private http: HttpClient,) {
  }

  articlesList: ResponseArticles[] = [];
  articlesListFind: ResponseArticles[] = [];
  articlesTitle: string = "";
  toggle1 = true;
  toggle2 = false;
  showFull = false;
  showDeleteIcon = false;


  ngOnInit(): void {
    this.scrollToUpPage();
    this.JosonToObject();
    this.showDeleteIcon = localStorage.getItem('loginTokenId') != null;
  }

  scrollToUpPage() {
    window.scrollTo(0, 0);
  }
  JosonToObject() {
    this.dbService.getArticle().subscribe((data => {
      this.articlesList = data;
    }))
    return this.articlesList;
  }


  downloadPdf(i: number) {
    let fileUrl = this.articlesList[i].url;
    let fileName = this.articlesList[i].titel;

    this.http.get<Blob>(fileUrl, { responseType: 'blob' as 'json' }).subscribe(
      (data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);

        // if you want to open PDF in new tab
        // window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName + '.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log('getPDF error: ', error);
      }
    );
  }

  findByArticles(articlesTitle: String) {
    if (articlesTitle === null || articlesTitle === "") {
      this.articlesList = [];
      this.articlesList = this.JosonToObject();

    }
    else {
      this.articlesListFind = [];
      this.articlesList.filter((data) => {
        if (data.titel === articlesTitle) {
          this.articlesList = [];
          this.articlesListFind.push({
            id: data.id,
            titel: data.titel,
            subject: data.subject,
            image: data.image,
            url: data.url,
            createdDate: data.createdDate
          });
        }
      })
      this.articlesList = [];
      this.articlesList = this.articlesListFind;
    }
  }

  onKey(event: any) {
    this.articlesTitle = event.target.value
    this.findByArticles(event.target.value);
  }

  enableDisableRule1() {
    this.toggle1 = true;
    this.toggle2 = false;
    this.articlesList = this.articlesList.sort((a, b) => a.id - b.id);
  }

  enableDisableRule2() {
    this.toggle2 = true;
    this.toggle1 = false;
    this.articlesList = this.articlesList.sort((a, b) => b.id - a.id);
  }

  getShortContent(subject: string): string {
    const words = subject.split(' ');
    return words.length > 100 ? words.slice(0, 100).join(' ') : subject;
  }

  toggleContent(): void {
    this.showFull = !this.showFull;
  }

  delete(id:any){
    console.log(id , "id");
    this.dbService.deleteItem("article",id);
  }

}
