import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'was-project';

  // popUpStatus = new Subject<Boolean>();

  ngOnInit(): void {
    // this.popUpStatus.subscribe(data => {
    //   console.log("data AppComponent: " + data);
    // });
  }

}
