<div class="sections" style="min-height: 100vh;padding-right: 50px;">
   <noscript><strong>We're sorry but landing doesn't work properly without JavaScript enabled. Please enable it to
         continue.</strong></noscript>
   <div>
      <div id="app" class="v-application v-application--is-rtl theme--light">
         <div class="v-application--wrap">
            <main class="v-main" style="padding: 0px;" data-booted="true">
               <div class="v-main__wrap">
                  <div>
                     <div class="row home-banner">
                        <div class="col-md-7 mobile-des"> <img style="border-radius: 4px;" alt="" width="70%"
                              src="{{imageSrc}}" class="image mobile-des3">
                        </div>
                        <div class="col-md-5 mobile-des">
                           <div style="margin-top: 15%;">
                              <input class="input-search-titel" placeholder="إبحث" (input)="onSearch()"
                                 [(ngModel)]="searchQuery">
                              <button class="button-search">
                                 <div class="image-search"><img src="assets/image/search-icon.svg" alt=""></div>
                              </button>

                              <ul *ngIf="searchQuery.length != 0">
                                 <li style="cursor: pointer;" *ngFor="let item of filteredItems"
                                    (click)="goToItemDetail(item.id)">{{ item.name
                                    }} </li>
                              </ul>

                           </div>
                           <div style="padding-top: 3%;">
                              البث المباشر
                           </div>
                           <hr class="mobile-des2" style="border: 1px solid #92856d;width: 50%;">
                           <div style="padding-top: 3%;">
                              <a href="https://www.youtube.com/@a.alodan" target="_blank">
                                 <img style="cursor: pointer;" src="assets/icons/live.svg" alt="" width="30%">
                              </a>
                           </div>
                        </div>
                     </div>
                     <!-- <div class="bBagt1qqsrpdWkUolvPM BQRckvUJerIVeHfmq5nG background-image">
                        <div class="container align-center" style="padding-top:10%">
                           <div class="tWKtcRVVycwaSQFjCIKa">
                              <img alt="" width="50%" src="assets/image/homeBanner.jpg">
                              <div style="justify-content: center;display: flex;align-items: center;margin-top: 30px;">
                                 <button (click)="scrollToLastPage()" type="button"
                                    class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--large secondary AoFPg11vdEkwzAYRnf4N"
                                    id="rf_home_intro_loginBtn"><span class="v-btn__content">
                                        ابدأ
                                       <i aria-hidden="true"
                                          class="v-icon notranslate mdi mdi-arrow-left theme--light rIbuesoOxiIcl36nKatN mr-5"
                                          style="font-size: 16px;"></i></span></button>
                              </div>
                           </div>
                           <div class="shaMys2eGZYpsGXbiefg">
                              <div>
                                 <div aria-label="najiz" role="img"
                                    class="v-image v-responsive header-eid-bg theme--light">
                                    <div class="v-responsive__sizer" style="padding-bottom: 86.6071%;"></div>
                                    <div class="v-image__image v-image__image--cover"
                                       style="background-image: url(&quot;assets/icons/mosquec.png&quot;); background-position: center center;">
                                    </div>
                                    <div class="v-responsive__content" style="width: 672px;"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> -->
                     <section class="GnxlF44uAE0tyNwAwInH add-padding">
                        <div class="container otl4Jx9qORQPsPapme9b  mb-10">
                           <div class="fZfihLTEBPIKy8nXSdR4" data-aos="fade-left" data-aos-duration="700"
                              data-aos-once='true'>
                              <h2 class="MBUncUm2g7QUwvti5JI7 px-5 mb-10">المحتوى العلمي</h2>
                           </div>
                           <div class="JVnnjDvvOZUehjMFwAl4" data-aos="fade-right" data-aos-duration="700"
                              data-aos-once='true'>
                              <div class="container H_QQyPlggfUUcUdcNK1z">
                                 <div class="row">
                                    <div
                                       class="col-sm-4 col-lg-4 col-xl-4 col-12 scrollAnimation scrollAnimation--active"
                                       routerLink="/Book">
                                       <div
                                          class="v-card v-sheet theme--light rlzlLPruBEWJVYmVlK4R OyAPth8zn0zbnbf_FbzM eLdpcikOIco5yp0CLeRl cardHeight"
                                          id="rf_home_eServices_card_9bd2e276-3110-4e32-a00e-330de0fec9a4">
                                          <div class="J_puyITl6wwikKajcucZ d-flex pb-0">
                                             <div class="rGzyRvtsQVUAN4T20f1Y mx-4">الأكثر استخداماً</div>
                                          </div>
                                          <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                             <div class="icon-book ml-2 icomoon-icon"></div>
                                             النصي
                                          </div>
                                          <div class="v-card__title aq5HDji_dHM65qtiK5k6 o5nz3bL9hBgyGmMC7ce_"
                                             style="margin: 0;">
                                             الكتب
                                          </div>
                                          <div class="v-card__text m18lwkE2_Y_QM442Veil xf7DejPVdPEbRM6olBtR"
                                             style="padding-bottom: 0;" *ngFor="let titel of bookTitels;">
                                             <!-- <img class="cardImagesBook" src="assets/icons/bookIcon.svg" /> -->
                                             <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                                <div class="icon-book ml-2 books-icon books-titel">
                                                   {{titel}}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="v-card__actions O0mkxYHBBU8YHs1t4Mqb px-4"><a
                                                class="v-btn v-btn--router v-btn--text theme--light v-size--large secondary--text HC2X4qrMYfwM_w0672aq EQG9p4b7ThE86KX8a29f"
                                                id="rf_home_eServices_cardAction_9bd2e276-3110-4e32-a00e-330de0fec9a4"
                                                aria-label=""><span class="v-btn__content">
                                                   الاطلاع عليها
                                                   <i aria-hidden="true"
                                                      class="v-icon notranslate mdi mdi-arrow-left theme--light z2w6OYphoF4xMS9um5wd mr-3"
                                                      style="font-size: 16px;"></i></span></a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       class="col-sm-4 col-lg-4 col-xl-4 col-12 scrollAnimation scrollAnimation--active"
                                       routerLink="/Article">
                                       <div
                                          class="v-card v-sheet theme--light rlzlLPruBEWJVYmVlK4R OyAPth8zn0zbnbf_FbzM eLdpcikOIco5yp0CLeRl cardHeight"
                                          id="rf_home_eServices_card_1c719954-3463-4754-85e1-1b319b937b86">
                                          <div class="J_puyITl6wwikKajcucZ d-flex pb-0">
                                             <!---->
                                             <!---->
                                             <div class="rGzyRvtsQVUAN4T20f1Y mx-4">الأكثر استخداماً</div>
                                          </div>
                                          <!---->
                                          <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                             <div class="icon-article ml-2 icomoon-icon"></div>
                                             النصي
                                          </div>
                                          <div class="v-card__title aq5HDji_dHM65qtiK5k6 o5nz3bL9hBgyGmMC7ce_"
                                             style="margin: 0;">
                                             المقالات
                                          </div>
                                          <div class="v-card__text m18lwkE2_Y_QM442Veil xf7DejPVdPEbRM6olBtR"
                                             style="padding-bottom: 0;" *ngFor="let titel of articleTitels;">
                                             <!-- <img class="cardImagesAr" src="assets/icons/articlesicon.svg" /> -->
                                             <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                                <div class="icon-article ml-2 books-icon books-titel">
                                                   {{titel}}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="v-card__actions O0mkxYHBBU8YHs1t4Mqb px-4"><a
                                                class="v-btn v-btn--router v-btn--text theme--light v-size--large secondary--text HC2X4qrMYfwM_w0672aq EQG9p4b7ThE86KX8a29f"
                                                id="rf_home_eServices_cardAction_1c719954-3463-4754-85e1-1b319b937b86"
                                                aria-label=""><span class="v-btn__content">
                                                   الاطلاع عليها
                                                   <i aria-hidden="true"
                                                      class="v-icon notranslate mdi mdi-arrow-left theme--light z2w6OYphoF4xMS9um5wd mr-3"
                                                      style="font-size: 16px;"></i></span></a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       class="col-sm-4 col-lg-4 col-xl-4 col-12 scrollAnimation scrollAnimation--active"
                                       routerLink="/Fatwa">
                                       <div
                                          class="v-card v-sheet theme--light rlzlLPruBEWJVYmVlK4R OyAPth8zn0zbnbf_FbzM eLdpcikOIco5yp0CLeRl cardHeight"
                                          id="rf_home_eServices_card_739b4d6e-c736-4d27-ba3e-a6d17dd561aa">
                                          <div class="J_puyITl6wwikKajcucZ d-flex pb-0">
                                             <!---->
                                             <!---->
                                             <div class="rGzyRvtsQVUAN4T20f1Y mx-4">الأكثر استخداماً</div>
                                          </div>
                                          <!---->
                                          <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                             <div class="icon-play ml-2 icomoon-icon"></div>
                                             الفتاوى
                                          </div>
                                          <div class="v-card__title aq5HDji_dHM65qtiK5k6 o5nz3bL9hBgyGmMC7ce_"
                                             style="margin: 0;">
                                             الفتاوى
                                          </div>
                                          <div class="v-card__text m18lwkE2_Y_QM442Veil xf7DejPVdPEbRM6olBtR"
                                             style="padding-bottom: 0;" *ngFor="let titel of fatwaTitels;">
                                             <!-- <img class="cardImagesVid" src="assets/icons/vidicon.svg" /> -->
                                             <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                                <div class="icon-play ml-2 books-icon books-titel">
                                                   {{titel}}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="v-card__actions O0mkxYHBBU8YHs1t4Mqb px-4"><a
                                                class="v-btn v-btn--router v-btn--text theme--light v-size--large secondary--text HC2X4qrMYfwM_w0672aq EQG9p4b7ThE86KX8a29f"
                                                id="rf_home_eServices_cardAction_739b4d6e-c736-4d27-ba3e-a6d17dd561aa"
                                                aria-label=""><span class="v-btn__content">
                                                   الاطلاع عليها
                                                   <i aria-hidden="true"
                                                      class="v-icon notranslate mdi mdi-arrow-left theme--light z2w6OYphoF4xMS9um5wd mr-3"
                                                      style="font-size: 16px;"></i></span></a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       class="col-sm-4 col-lg-4 col-xl-4 col-12 scrollAnimation scrollAnimation--active"
                                       routerLink="/Video">
                                       <div
                                          class="v-card v-sheet theme--light rlzlLPruBEWJVYmVlK4R OyAPth8zn0zbnbf_FbzM eLdpcikOIco5yp0CLeRl cardHeight"
                                          id="rf_home_eServices_card_739b4d6e-c736-4d27-ba3e-a6d17dd561aa">
                                          <div class="J_puyITl6wwikKajcucZ d-flex pb-0">
                                             <!---->
                                             <!---->
                                             <div class="rGzyRvtsQVUAN4T20f1Y mx-4">الأكثر استخداماً</div>
                                          </div>
                                          <!---->
                                          <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                             <div class="icon-play ml-2 icomoon-icon"></div>
                                             المرئي
                                          </div>
                                          <div class="v-card__title aq5HDji_dHM65qtiK5k6 o5nz3bL9hBgyGmMC7ce_"
                                             style="margin: 0;">
                                             المرئيات
                                          </div>
                                          <div class="v-card__text m18lwkE2_Y_QM442Veil xf7DejPVdPEbRM6olBtR"
                                             style="padding-bottom: 0;" *ngFor="let titel of videoTitels;">
                                             <!-- <img class="cardImagesVid" src="assets/icons/vidicon.svg" /> -->
                                             <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                                <div class="icon-play ml-2 books-icon books-titel">
                                                   {{titel}}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="v-card__actions O0mkxYHBBU8YHs1t4Mqb px-4"><a
                                                class="v-btn v-btn--router v-btn--text theme--light v-size--large secondary--text HC2X4qrMYfwM_w0672aq EQG9p4b7ThE86KX8a29f"
                                                id="rf_home_eServices_cardAction_739b4d6e-c736-4d27-ba3e-a6d17dd561aa"
                                                aria-label=""><span class="v-btn__content">
                                                   الاطلاع عليها
                                                   <i aria-hidden="true"
                                                      class="v-icon notranslate mdi mdi-arrow-left theme--light z2w6OYphoF4xMS9um5wd mr-3"
                                                      style="font-size: 16px;"></i></span></a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       class="col-sm-4 col-lg-4 col-xl-4 col-12 scrollAnimation scrollAnimation--active"
                                       routerLink="/Speeches">
                                       <div
                                          class="v-card v-sheet theme--light rlzlLPruBEWJVYmVlK4R OyAPth8zn0zbnbf_FbzM eLdpcikOIco5yp0CLeRl cardHeight"
                                          id="rf_home_eServices_card_739b4d6e-c736-4d27-ba3e-a6d17dd561aa">
                                          <div class="J_puyITl6wwikKajcucZ d-flex pb-0">
                                             <!---->
                                             <!---->
                                             <div class="rGzyRvtsQVUAN4T20f1Y mx-4">الأكثر استخداماً</div>
                                          </div>
                                          <!---->
                                          <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                             <div class="icon-book ml-2 icomoon-icon"></div>
                                             النصي
                                          </div>
                                          <div class="v-card__title aq5HDji_dHM65qtiK5k6 o5nz3bL9hBgyGmMC7ce_"
                                             style="margin: 0;">
                                             خطب الجمعة
                                          </div>
                                          <div class="v-card__text m18lwkE2_Y_QM442Veil xf7DejPVdPEbRM6olBtR"
                                             style="padding-bottom: 0;" *ngFor="let titel of speecheTitels;">
                                             <!-- <img class="cardImagesVid" src="assets/icons/vidicon.svg" /> -->
                                             <div class="v-card__title N18VRzk8zU0V6vAZFeWm pb-0">
                                                <div class="icon-book ml-2 books-icon books-titel">
                                                   {{titel}}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="v-card__actions O0mkxYHBBU8YHs1t4Mqb px-4"><a
                                                class="v-btn v-btn--router v-btn--text theme--light v-size--large secondary--text HC2X4qrMYfwM_w0672aq EQG9p4b7ThE86KX8a29f"
                                                id="rf_home_eServices_cardAction_739b4d6e-c736-4d27-ba3e-a6d17dd561aa"
                                                aria-label=""><span class="v-btn__content">
                                                   الاطلاع عليها
                                                   <i aria-hidden="true"
                                                      class="v-icon notranslate mdi mdi-arrow-left theme--light z2w6OYphoF4xMS9um5wd mr-3"
                                                      style="font-size: 16px;"></i></span></a>
                                          </div>
                                       </div>
                                    </div>

                                 </div>
                              </div>
                              <div class="d-flex justify-end pa-3 mt-10">
                              </div>
                           </div>
                        </div>
                     </section>
                     <div class="container ykFoTX3_go3FyPYk3Yii overflow-hidden add-padding">
                        <!-- <p class="MQOhAWdVWzcYAdzZvJqZ" data-aos="fade-down" data-aos-duration="700"
                           data-aos-once='true'>المحتوى</p> -->
                        <div class="row my-10" data-aos="fade-right" data-aos-duration="700" data-aos-once='true'>
                           <div class="col-lg-3 col-xl-3 col-12">
                              <div class="E6NmzhPUlCovmRx10Tfk d-flex flex-column align-center">
                                 <div class="fIQF06GpB9siyY8o337c icon icon-article icomoon-icon" data-aos="fade-left"
                                    data-aos-duration="700" data-aos-once='true'></div>

                                 <div class="nHz7WhQZsYA0rqu9Hzah counter" data-value="111885">20+</div>
                                 <p class="K9b3Ni7VfoQPfAumdd6V">المقالات</p>
                              </div>
                           </div>
                           <div class="col-lg-3 col-xl-3 col-12">
                              <div class="E6NmzhPUlCovmRx10Tfk d-flex flex-column align-center">
                                 <div class="fIQF06GpB9siyY8o337c icon-film icomoon-icon" data-aos="fade-left"
                                    data-aos-duration="700" data-aos-once='true'></div>
                                 <div class="nHz7WhQZsYA0rqu9Hzah counter" data-value="0">50+</div>
                                 <p class="K9b3Ni7VfoQPfAumdd6V">الدروس</p>
                              </div>
                           </div>
                           <div class="col-lg-3 col-xl-3 col-12">
                              <div class="E6NmzhPUlCovmRx10Tfk d-flex flex-column align-center">
                                 <div class="fIQF06GpB9siyY8o337c icon icon-play icomoon-icon" data-aos="fade-left"
                                    data-aos-duration="700" data-aos-once='true'></div>
                                 <div class="nHz7WhQZsYA0rqu9Hzah counter" data-value="25372">30+</div>
                                 <p class="K9b3Ni7VfoQPfAumdd6V">المحاضرات</p>
                              </div>
                           </div>
                           <div class="col-lg-3 col-xl-3 col-12">
                              <div class="E6NmzhPUlCovmRx10Tfk d-flex flex-column align-center">
                                 <div class="fIQF06GpB9siyY8o337c icon-book icomoon-icon" data-aos="fade-left"
                                    data-aos-duration="700" data-aos-once='true'></div>
                                 <div class="nHz7WhQZsYA0rqu9Hzah counter" data-value="74312">20+</div>
                                 <p class="K9b3Ni7VfoQPfAumdd6V">كتب الشيخ</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!--  -->



                  </div>
               </div>
            </main>
         </div>
      </div>
   </div>
</div>