<header class="header header--beige" dir="rtl">
    <div class="header__container">
        <div class="header__logo">
            <a href="/" class="header__logoLink" style="position: absolute;
            right: 2%;
            top: 0;
            width: 160px;">
                <img alt="" class="header__logoImg icon-mobole" width="80%" src="assets/image/logoWebsite.svg" />
            </a>
        </div>
        <nav class="header__menuWrapper mobile-header-des" style="position: absolute;
        left: 35%;
        top: 30%;">
            <div class="header__menu mobile-header-des-mun" style="text-align: center;">
                <a routerLink="/" class="header__menuItem">الرئيسية</a>
                <a routerLink="/Book" class="header__menuItem">الكتب</a>
                <a routerLink="/Article" class="header__menuItem">المقالات</a>
                <a routerLink="/Fatwa" class="header__menuItem">الفتاوى</a>
                <a routerLink="/Video" class="header__menuItem">المرئيات</a>
                <a routerLink="/Speeches" class="header__menuItem">خطب الجمعة</a>
                <a (click)="popupStatus(true)" class="header__menuItem header__menuItem--login">الدخول</a>
            </div>
        </nav>
        <img class="icon-mobole-2" alt="" width="25%" src="assets/image/banner_2.svg"
            style="position: absolute;left: 10px;">
        <div class="header__right" style="position: absolute;
        top: 40px;
        right: 45%;">
            <div class="header__logo">
            </div>
            <div class="header__logo">

            </div>
            <div class="header__hamburger">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</header>



<app-pop-up *ngIf="popUp"></app-pop-up>