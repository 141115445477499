import { SafeResourceUrl } from "@angular/platform-browser";

export interface ResponseVideo {
  id: number;
  titel: string;
  videoLink: string;
  createdDate: Date;
  typeVideo: number;
}
export interface ResponseBook {
  id: number;
  titel: string;
  url: string;
  createdDate: Date;
  image: string;
  bookType: number;
}
export interface ResponseArticles {
  id: number;
  titel: string;
  subject: string;
  image: string;
  url: string;
  createdDate: Date;
}

export interface ResponseFatwa {
  id: number;
  titel: string;
  subject: string;
  url: string;
  createdDate: Date;
}

export interface ResponseSpeeches {
  id: number;
  titel: string;
  subject: string;
  url: string;
  image: string;
  createdDate: Date;
}

export interface ResponseUsers {
  email: string;
  password: string;
}


//request 

export interface RequesteVideo {
  titel: string;
  videoLink: string;
  createdDate: Date;
  typeVideo: number;
}
export interface RequestBook {
  titel: string;
  createdDate: Date;
  url: string;
  image: string;
  bookType: number;
}
export interface RequestArticles {
  titel: string;
  subject: string;
  image: string;
  url: string;
  createdDate: Date;
}

export interface RequestFatwa {
  titel: string;
  subject: string;
  url: string;
  createdDate: Date;
}

export interface RequestSpeeches {
  titel: string;
  subject: string;
  url: string;
  image: string;
  createdDate: Date;
}


export interface Data {
  id: string;
  url: string;
}

export const bookDownListenum = [
  {
    "id": 1,
    "name": "العلوم الشرعية"
  },
  {
    "id": 2,
    "name": "العقيدة"
  },
  {
    "id": 3,
    "name": "الفقة"
  },
  {
    "id": 4,
    "name": "الحديث"
  }
]



export const vidoeDownListenum = [
  {
    "id": 1,
    "name": "الطويلة"
  },
  {
    "id": 2,
    "name": "القصيرة"
  }
]
