<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStylelogin}" style="position:fixed;
z-index:999;
width:100%;
height:100%;
background-color: black;
background-color:rgba(0,0,0,.75)">
    <div class="modal-dialog" role="document" style="text-align:right;margin-top: 20%;">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-body">

                    <div class="card-block">
                        <h4 class="card-title">تسجيل دخول</h4>
                        <h5 *ngIf="errorNameOrPassword" class="error">اسم المستخدم او كلمة المرور خطأ</h5>
                        <div style="text-align:right;">
                            <form [formGroup]="f" novalidate>
                                <div class="form-group"
                                    [class.has-danger]="((f.get('name')?.hasError('required') || f.get('name')?.hasError('email')) && f.get('name')?.hasError('email') )&& f.get('name')?.touched">
                                    <label for="exampleInputEmail1">الايميل</label>
                                    <input style="text-align:right" formControlName="name" type="email"
                                        class="form-control" id="exampleInputEmail1"
                                        [class.form-control-danger]="f.get('name')?.hasError('required') && f.get('name')?.touched"
                                        placeholder="ادخل الايميل">
                                    <div *ngIf="f.get('name')?.hasError('required') && f.get('name')?.touched"
                                        class="form-control-feedback">الرجاء ادخال اسم المستخدم
                                    </div>
                                    <div *ngIf="f.get('name')?.hasError('email') && f.get('name')?.touched && !f.get('name')?.hasError('required')"
                                        class="form-control-feedback">اسم المستخدم خاطئ</div>
                                </div>
                                <div style="margin-top: 30px;" class="form-group"
                                    [class.has-danger]="(f.get('password')?.hasError('required') || f.get('password')?.hasError('minlength')) && f.get('password')?.touched">
                                    <label for="exampleInputPassword1">كلمة المرور</label>
                                    <input style="text-align:right" formControlName="password" type="password"
                                        class="form-control" id="exampleInputPassword1"
                                        [class.form-control-danger]="f.get('password')?.hasError('required') && f.get('password')?.touched"
                                        placeholder="كلمة المرور">

                                    <div *ngIf="f.get('password')?.hasError('required') && f.get('password')?.touched"
                                        class="form-control-feedback">كلمة المرور الزامية</div>

                                    <div *ngIf="f.get('password')?.hasError('minlength') && f.get('password')?.touched"
                                        class="form-control-feedback">الحد الأدنى للطول هو 6 أحرف. أضف المزيد!
                                    </div>
                                </div>

                                <div style="margin-top:30px;">
                                    <button type="submit" (click)="login(modalTemplate)" class="btn btn-primary">
                                        تسجيل دخول</button>
                                    <!-- <button type="submit" (click)="onReset()" class="btn">حذف</button> -->
                                    <button type="submit" (click)="closePopuplogin()" class="btn btn-secondary"
                                        style="float: left;">اغلاق</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}" style="position:fixed;
z-index:999;
width:100%;
height:100%;
background-color: black;
background-color:rgba(0,0,0,.75)">
    <div class="modal-dialog" role="document" style="text-align:right; text-align:right;margin-top: 12%;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">يرجى ادخال البيانات</h4>
            </div>
            <div class="modal-body">
                <div class="dropdown" style="text-align:center;">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        الرجاء اختيار الصفحة : {{dropName}}
                    </button>
                    <div style="text-align: right;" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li *ngFor="let drob of drobDown" value="{{drob.id}}" (click)="selectValue(drob)"
                            class="dropdown-item">{{drob.name}}
                        </li>
                    </div>
                </div>
                <div *ngIf="errorFlag" style="text-align:center; font-size:15px; color: red;margin-top: 10px;">
                    {{errorMes}}
                </div>
                <div class="modal-body">
                    <div *ngIf="showTitle" class="mb-3">
                        <label for="title" class="form-label">الرجاء ادخال الموضوع</label>
                        <input [(ngModel)]="title" class="form-control" id="title">
                    </div>
                    <div *ngIf="showBookType" class="mb-3">
                        <div class="dropdown" style="text-align:center;">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{bookTypeText}}
                            </button>
                            <div style="text-align: right;" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li *ngFor="let drob of bookDownList" value="{{drob.id}}"
                                    (click)="setValueBookList(drob)" class="dropdown-item">{{drob.name}}
                                </li>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showvideo" class="mb-3">
                        <div class="dropdown" style="text-align:center;">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{videoTypeText}}
                            </button>
                            <div style="text-align: right;" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li *ngFor="let drob of videoDowmList" value="{{drob.id}}"
                                    (click)="setValueVideoTypeList(drob)" class="dropdown-item">{{drob.name}}
                                </li>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showvideo" class="mb-3">
                        <label for="link" class="form-label">الرجاء ادخال رابط اليوتيوب</label>
                        <input [(ngModel)]="link" class="form-control" id="link">
                    </div>
                    <div *ngIf="showSub" class="mb-3">
                        <label for="subject" class="form-label">{{subjectTitle}}</label>
                        <textarea [(ngModel)]="subject" class="form-control" id="subject" rows="3"></textarea>
                    </div>
                    <div *ngIf="showImage" class="mb-3">
                        <label for="image" class="form-label">{{imageTitle}}</label>
                        <input (change)="handleUploadImage($event)" class="form-control" type="file" id="image">
                    </div>
                    <div *ngIf="showFile" class="mb-3">
                        <label for="file" class="form-label">{{fileTitle}}</label>
                        <input (change)="handleUploadFile($event)" class="form-control" type="file" id="file">
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="display:block !important;">
                <button type="button" class="btn btn-success" (click)="saveValue()">
                    حفظ
                </button>
                <button type="button" class="btn btn-danger" (click)="closePopup()" style="float: left;">
                    اغلاق
                </button>
            </div>
        </div>
    </div>
</div>