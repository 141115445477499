import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from "aos";
import { DBService } from 'src/app/service/db.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  imageSrc: string = "";
  bookTitels: string[] = []
  articleTitels: string[] = []
  fatwaTitels: string[] = []
  videoTitels: string[] = []
  speecheTitels: string[] = []

  searchQuery: string = '';
  items = [
    { id: 1, name: 'الكتب' },
    { id: 2, name: 'المقالات' },
    { id: 3, name: 'الفتاوى' },
    { id: 4, name: 'المرئيات' },
    { id: 5, name: 'خطب الجمعة' },
  ];
  filteredItems = [...this.items];


  constructor(private dbService: DBService, private router: Router) { }

  ngOnInit(): void {
    AOS.init({ disable: 'mobile' });

    this.imageSrc = 'assets/image/homeBanner.jpg';

    const intervalId = setInterval(() => {
      const imageElement = document.querySelector('.image');
      // imageElement!.classList.add('image-changing');

      const images = ['assets/image/homeBanner2.jpg', 'assets/image/homeBanner.jpg'];
      let index = Math.floor(Math.random() * images.length);
      this.imageSrc = images[index];
      setTimeout(() => {
        // imageElement!.classList.remove('image-changing');
      }, 500);
    }, 5000);
    this.bookTitels = this.dbService.getTopThreeSubjectBookTitels();
    this.articleTitels = this.dbService.getTopThreeSubjectArticleTitels();
    this.fatwaTitels = this.dbService.getTopThreeSubjectFatwaTitels();
    this.videoTitels = this.dbService.getTopThreeSubjectVideoTitels();
    this.speecheTitels = this.dbService.getTopThreeSubjectSpeecheTitels();
  }

  scrollToLastPage() {
    window.scrollTo({ left: 0, top: 2500, behavior: 'smooth' });
  }

  onSearch() {
    this.filteredItems = this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  goToItemDetail(itemId: number) {
    if (itemId === 1) {
      this.router.navigate(['/Book']);
    } else if (itemId === 2) {
      this.router.navigate(['/Video']);
    } else if (itemId === 3) {
      this.router.navigate(['/Article']);
    } else if (itemId === 4) {
      this.router.navigate(['/Fatwa']);
    } else if (itemId === 5) {
      this.router.navigate(['/Speeches']);
    }
  }

}
