<div class="container" style="margin-top: 155px;">
    <!-- <div class="row">
        <div style="width: 100%; height: 0.1px; background-color: #252524;"></div>
    </div> -->
    <div class="row from-top">
        <div class="col-12 titel">جميع الفتاوى</div>
    </div>
    <div class="row from-top">
        <div class="col-12 text-right text-titel">
            <img src="assets/image/home-icon.svg" width="18px" style="padding-bottom: 2px;" />
            الرئيسية > الفتاوى
        </div>
    </div>
    <div class="row from-top">
        <!-- <div class="col">
            <button (click)="enableDisableRule1()" class="sort-button1"
                [ngClass]="{'button-background1' : toggle1, 'button-background2': toggle2}">
                <div class="sort-text">الأحدث</div>
            </button>
            <button (click)="enableDisableRule2()" class="sort-button2"
                [ngClass]="{'button-background1' : toggle2, 'button-background2': toggle1}">
                <div class="sort-text">الأقدم</div>
            </button>
        </div> -->
        <div class="col" style="text-align: right;">
            <input (keyup)="onKey($event)" class="input-search-titel" placeholder="إبحث عن فتاوى ">
            <button (click)="findByFatwa(fatwaTitle)" class="button-search">
                <img src="assets/image/search-icon.svg" alt="">
            </button>
        </div>
    </div>
</div>
<div class="container vertical-scrollable from-top-articles" style="text-align: center;">
    <div class="row" *ngIf="fatwaList.length != 0">
        <div class="col-12 background" *ngFor="let fatwa of fatwaList; let i = index">
            <img class="col-2 image-content" src="/assets/image/articles-icon.png" width="100%">
            <div class="col-2 titel-content">{{fatwa.titel}}</div>
            <div class="col-6 subject-content">
                <span *ngIf="!showFull">
                    {{ getShortContent(fatwa.subject) }}...
                </span>
                <span *ngIf="showFull">
                    {{ fatwa.subject }}
                    <div *ngIf="fatwa.url !='' && fatwa.url != 'null'" class="audio-player">
                        <audio #audio controls>
                            <source [src]="fatwa.url" type="audio/mp3">
                        </audio>
                    </div>
                </span>
            </div>
            <div class="col-2" style="float: left;cursor: pointer;text-align: left; padding-bottom: 10px;
             padding-left: 20px;" (click)="toggleContent()">
                <img *ngIf="!showFull" src="/assets/icons/down-arrow-svgrepo-com.svg" width="10%">
                <img *ngIf="showFull" src="/assets/icons/up-arrow-svgrepo-com.svg" width="10%">
            </div>
            <button *ngIf="showDeleteIcon" class="delete-but" (click)="delete(fatwa.id)">حذف</button>
        </div>
    </div>
    <div class="msg" *ngIf="fatwaList.length === 0">
        لا يوجد فتاوى 
    </div>
</div>