import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DBService } from '../service/db.service';
import { RequesteVideo, RequestBook, RequestArticles, RequestSpeeches, bookDownListenum, RequestFatwa, vidoeDownListenum } from '../interface/response';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from "rxjs/operators";


@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css']
})
export class PopUpComponent implements OnInit {
  title: string = "";
  link: string = "";
  subject: string = "";
  bookType: number = 0;
  videoType: number = 0;
  bookTypeText: string = "الرجاء اختيار التصنيف";
  videoTypeText: string = "الرجاء اختيار التصنيف";
  image: string = "";
  file: string = "";

  id: number = 0;

  fileUpload: any = null;
  url !: string;
  idFile: string = "";
  fileStore!: string;


  fileTitle: string = "الرجاء ارفاق الملف";
  imageTitle: string = "الرجاء ارفاق الصورة";
  subjectTitle: string = "الرجاء ادخال المقال";
  dropName: string = "";


  errorMes = "الرجاء التحقق من الحقول";
  errorFlag = false;

  showLogout = false;
  showDeleteIcon = false;

  f: FormGroup;
  errorNameOrPassword = false;
  modalTemplate: any;

  constructor(private fb: FormBuilder,
    private dbService: DBService,
    @Inject(AngularFireStorage) private storage: AngularFireStorage) {
    this.f = this.fb.group({
      name: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, , Validators.minLength(6)]],
    });
  }

  displayStyle = "none";
  displayStylelogin = "none";

  showTitle = false;
  showSub = false;
  showvideo = false;
  showImage = false;
  showFile = false;
  showBookType = false;
  showFatwaType = false;


  drobDown = [
    {
      "id": 1,
      "name": "الكتب"
    },
    {
      "id": 2,
      "name": "المقاطع"
    },
    {
      "id": 3,
      "name": "المقالات"
    },
    {
      "id": 4,
      "name": "الفتاوى"
    },
    {
      "id": 5,
      "name": "الخطب"
    }
  ]

  bookDownList = bookDownListenum;
  videoDowmList = vidoeDownListenum;

  ngOnInit(): void {
    this.dbService.getFile();
    this.openPopuplogin();
  }

  onSubmitVideo() {
    let request: RequesteVideo = {
      "titel": this.title,
      "videoLink": this.link.replace("youtu.be/", "youtube.com/embed/"),
      "createdDate": new Date(),
      "typeVideo": this.videoType,
    }
    if (request.titel === "" || request.titel === null
      || request.videoLink === "" || request.videoLink === null
    ) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
      this.dbService.addVideo(request)
        .then((data) => {
          console.log(data);
          this.closePopup();
          this.onResetInput();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  setValueBookList(bookType: any) {
    this.bookType = bookType.id;
    this.bookTypeText = bookType.name
  }

  setValueVideoTypeList(videoType: any) {
    this.videoType = videoType.id;
    this.videoTypeText = videoType.name
  }

  onSubmitBook() {
    if (this.title === "" || this.title === null) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
      var name = this.title;
      let filePath = "/book/" + name;
      const fileRef = this.storage.ref(filePath);
      this.storage.upload(filePath, this.fileUpload).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.url = url;
            this.dbService.insertFile(this.id, this.url);
            let request: RequestBook = {
              "titel": this.title,
              "createdDate": new Date(),
              "url": this.url,
              "image": this.image,
              "bookType": this.bookType
            }
            this.dbService.addBook(request, this.fileUpload)
              .then((data) => {
                this.closePopup();
                alert('تم رفع الملف بنجاح');
                this.onResetInput();
              })
              .catch((error) => {
                console.log(error);
              });
          })
        })
      ).subscribe();
    }
  }

  onSubmitArticle() {
    if (this.title === "" || this.title === null) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
      var name = this.title;
      let filePath = "/article/" + name;
      const fileRef = this.storage.ref(filePath);
      this.storage.upload(filePath, this.fileUpload).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.url = url;
            this.dbService.insertFile(this.id, this.url);
            let request: RequestArticles = {
              "titel": this.title,
              "subject": this.subject,
              "image": this.image,
              "url": this.url,
              "createdDate": new Date(),
            }
            this.dbService.addArticle(request)
              .then((data) => {
                this.closePopup();
                alert('تم رفع الملف بنجاح');
                this.onResetInput();
              })
              .catch((error) => {
                console.log(error);
              });
          })
        })
      ).subscribe();
    }
  }

  onSubmitFatwa() {
    if (this.title === "" || this.title === null) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
      var name = this.title;
      let filePath = "/fatwa/" + name;
      const fileRef = this.storage.ref(filePath);
      this.storage.upload(filePath, this.fileUpload).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.url = url;
            this.dbService.insertFile(this.id, this.url);
            let request: RequestFatwa = {
              "titel": this.title,
              "subject": this.subject,
              "url": this.url,
              "createdDate": new Date(),
            }
            this.dbService.addFatwa(request)
              .then((data) => {
                this.closePopup();
                alert('تم رفع الملف بنجاح');
                this.onResetInput();
              })
              .catch((error) => {
                console.log(error);
              });
          })
        })
      ).subscribe();
    }
  }

  handleUploadImage(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image = reader.result?.toString() != null ? reader.result?.toString() : "";
    };
  }

  handleUploadFile(event: any) {
    this.fileUpload = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.fileUpload);
    reader.onload = () => {
      this.file = reader.result?.toString() != null ? reader.result?.toString() : "";
    };
  }

  onSubmitSpeeches() {
    if (this.title === "" || this.title === null) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
      var name = this.title;
      let filePath = "/speeches/" + name;
      const fileRef = this.storage.ref(filePath);
      this.storage.upload(filePath, this.fileUpload).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.url = url;
            this.dbService.insertFile(this.id, this.url);
            let request: RequestSpeeches = {
              "titel": this.title,
              "subject": this.subject,
              "image": this.image,
              "url": this.url,
              "createdDate": new Date(),
            }
            this.dbService.addSpeeches(request)
              .then((data) => {
                this.closePopup();
                alert('تم رفع الملف بنجاح');
                this.onResetInput();
              })
              .catch((error) => {
                console.log(error);
              });
          })
        })
      ).subscribe();
    }
  }

  login(modal: any) {
    if (this.f.get("name")?.value === null || this.f.get("name")?.value === "") {
      this.f.get("name")?.markAsTouched();
    }
    if (this.f.get("password")?.value === null || this.f.get("password")?.value === "") {
      this.f.get("password")?.markAsTouched();
    }
    if (this.f.get("password")?.value != null && this.f.get("password")?.value != ""
      && this.f.get("name")?.value != null && this.f.get("name")?.value != "") {
      this.checklogin(modal);
    }
  }

  checklogin(modal: any) {
    if (this.f.valid) {
      this.dbService.getUsers(this.f.get("name")?.value, this.f.get("password")?.value).subscribe((data => {
        let user = data;
        if (user.length != 0) {
          let token = {
            "token": this.dbService.randomString(50),
            "userEmail": this.f.get("name")?.value
          }
          this.dbService.addToken(token);
          this.errorNameOrPassword = false;
          this.closePopuploginWithOutLocation();
          this.showLogout = true;
          this.onResetLogIn();
          this.openPopup();
        }
        else {
          this.errorNameOrPassword = true;
        }
      }));
    } else {
      let temp = this.f.controls['name'];
      Object.keys(this.f.controls).forEach(key => {
        this.f.get(key)?.value.markAsTouched();
      });
    }
  }

  onResetLogIn(): void {
    this.errorNameOrPassword = false;
    this.f.reset();
  }

  onResetInput() {
    this.title = "";
    this.subject = "";
    this.link = "";

  }

  selectValue(account: any) {
    this.showTitle = false;
    this.showFile = false;
    this.showImage = false;
    this.showSub = false;
    this.showvideo = false;
    this.showBookType = false;
    this.showFatwaType = false;
    this.dropName = "";

    if (account.id == 1) {
      this.showTitle = true;
      this.showImage = true;
      this.showFile = true;
      this.showBookType = true;
      this.id = 1;
      this.fileTitle = "الرجاء ادخال ملف الكتاب";
      this.imageTitle = "الرجاء ادخال صورة للكتاب";
      this.dropName = "الكتب";

    } else if (account.id == 2) {
      this.showTitle = true;
      this.showvideo = true;
      this.id = 2;
      this.dropName = "المقاطع";
    }
    else if (account.id == 3) {
      this.showTitle = true;
      this.showSub = true;
      this.showImage = true;
      this.showFile = true;
      this.id = 3;
      this.fileTitle = "الرجاء ادخال ملف المقال";
      this.imageTitle = "الرجاء ادخال صورة للمقال";
      this.subjectTitle = "الرجاء ادخال المقال";
      this.dropName = "المقالات";
    }
    else if (account.id == 4) {
      this.showTitle = true;
      this.showSub = true;
      this.showImage = false;
      this.showFile = true;
      this.showFatwaType = true;
      this.id = 4;
      this.fileTitle = "الرجاء ادخال الملف الصوتي فقط للفتوى";
      this.subjectTitle = "الرجاء ادخال الفتوى";
      this.dropName = "الفتاوى";
    }
    else if (account.id == 5) {
      this.showTitle = true;
      this.showSub = true;
      this.showImage = true;
      this.showFile = true;
      this.id = 5;
      this.fileTitle = "الرجاء ادخال ملف الخطبة";
      this.imageTitle = "الرجاء ادخال صورة الخطبة";
      this.subjectTitle = "الرجاء ادخال الخطبة";
      this.dropName = "الخطب";
    }
  }

  saveValue() {
    if (this.id == 1) {
      this.onSubmitBook();
      this.dbService.changePopUpStatus(false);
    }
    else if (this.id == 2) {
      this.onSubmitVideo();
      this.dbService.changePopUpStatus(false);
    }
    else if (this.id == 3) {
      this.onSubmitArticle();
      this.dbService.changePopUpStatus(false);
    }
    else if (this.id == 4) {
      this.onSubmitFatwa();
      this.dbService.changePopUpStatus(false);
    }
    else if (this.id == 5) {
      this.onSubmitSpeeches();
      this.dbService.changePopUpStatus(false);
    }
  }


  openPopuplogin() {
    if (localStorage.getItem('loginTokenId') != null) {
      this.dbService.getToken(localStorage.getItem('loginTokenId')!).subscribe((data => {
        let token: any;
        token = data;
        if (localStorage.getItem('loginToken') === token.token) {
          this.openPopup();
          this.showDeleteIcon = true;
          this.dbService.setValueDeleteIcon(this.showDeleteIcon);
        }
        else {
          localStorage.clear();
          this.displayStylelogin = "block";
          this.showDeleteIcon = false;
          this.dbService.setValueDeleteIcon(this.showDeleteIcon);
        }
      }));
    } else {
      localStorage.clear();
      this.displayStylelogin = "block";
      this.showDeleteIcon = false;
      this.dbService.setValueDeleteIcon(this.showDeleteIcon);
    }
  }

  logout() {
    this.dbService.deleteToken(localStorage.getItem('loginTokenId')!);
    localStorage.clear();
    this.showLogout = false;
  }

  closePopuplogin() {
    this.dbService.changePopUpStatus(false);
    this.displayStylelogin = "none";
    this.onResetLogIn();
  }
  closePopuploginWithOutLocation() {
    this.displayStylelogin = "none";
    this.onResetLogIn();
  }

  openPopup() {
    this.displayStyle = "block";
  }

  closePopup() {
    this.dbService.changePopUpStatus(false);
    this.displayStyle = "none";
    this.onResetInput();
    this.errorFlag = false;
    this.showTitle = false;
    this.showFile = false;
    this.showImage = false;
    this.showSub = false;
    this.showvideo = false;
  }

}

